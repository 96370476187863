
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";

interface ILoginHistory {
  agent_id: string;
  code: string;
  agent: string;
  ip_address: string;
  createdAt: string;
}

export default defineComponent({
  name: "LoginHistory",
  components: {
    Datatable,
  },
  setup(props, { emit }) {
    // vue variables
    const { t } = useI18n();
    // table headers
    const tableHeader = ref([
      {
        name: t("Agents.Name"),
        key: "agent",
        text: true,
      },
      {
        name: t("LoginHistory.IP"),
        key: "ip_address",
        text: true,
      },
      {
        name: t("LoginHistory.Block"),
        key: "block",
        customslot: true,
      },
    ]);
    const tableData = ref<Array<ILoginHistory>>([]);
    /**
     * Get Histories
     *
     */
    const getHistories = async () => {
      const logs: ILoginHistory[] = await ApiService.get(
        `/agent/login/block/histories`
      )
        .then((res) => res.data)
        .catch(() => []);
      tableData.value.splice(0, tableData.value.length, ...logs);
    };
    /**
     * Unblock IP
     *
     */
    const unblockIP = async (history: ILoginHistory) => {
      Swal.fire({
        title: history.ip_address + " " + t("LoginHistory.Unblock"),
        text: t("LoginHistory.UnblockMessage"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("LoginHistory.Confirm"),
        cancelButtonText: t("LoginHistory.Cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.post("/agent/login/block-unblock", {
            agent_id: history.agent_id,
            ip_address: history.ip_address,
            action: "UNBLOCK",
          })
            .then(() => {
              Swal.fire(
                t("LoginHistory.Unblock"),
                t("LoginHistory.UnblockSuccess"),
                "success"
              );
              emit("refresh");
            })
            .catch(() => {
              Swal.fire(
                t("LoginHistory.Unblock"),
                t("LoginHistory.UnblockFailed"),
                "error"
              );
              emit("refresh");
            });
        }
      });
    };
    /**
     * On Mounted
     *
     */
    onMounted(() =>
      // get Histories
      getHistories()
    );
    return {
      tableHeader,
      tableData,
      getHistories,
      unblockIP,
    };
  },
});
