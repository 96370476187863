
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import BlockLogin from "./BlockLogin.vue";
import UnblockLogin from "./UnblockLogin.vue";

export default defineComponent({
  name: "LoginHistory",
  components: {
    BlockLogin,
    UnblockLogin,
  },
  setup(props) {
    // vue variables
    const { t } = useI18n();
    /**
     * On Mounted
     *
     */
    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs(t("Menu.LoginHistoryIpManage"), [
        t("Menu.AgentManage"),
      ]);
    });
  },
});
