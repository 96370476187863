import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 col-xl-6 col-lg-6" }
const _hoisted_3 = { class: "col-12 col-xl-6 col-lg-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BlockLogin = _resolveComponent("BlockLogin")!
  const _component_UnblockLogin = _resolveComponent("UnblockLogin")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BlockLogin)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_UnblockLogin)
    ])
  ]))
}